.success-snackbar {
  border-radius: 5px;
  padding: 15px;
  border-left: 5px solid hsl(153, 60%, 53%);
}

.error-snackbar {
  border-radius: 5px;
  padding: 15px;
  border-left: 5px solid hsl(6, 92%, 65%);
}
